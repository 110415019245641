import classnames from 'classnames'
import React, { FC, useState } from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'

import {
  Content,
  MinimalToggleIcon,
  Opener,
  SupportingIcon,
  Wrapper,
} from './Accordion.styles'
import { sanitiseLabel, sanitiseVariant } from './utils'
import { FullWidthToggleIcon } from './FullWidthToggleIcon'

export type Props = {
  id?: string
  text: string
  icon?: string
  centered?: boolean
  marginBottom?: string
  childBlock?: boolean
  hiddenAt?: string
  showChevron?: boolean
  variant?: 'fullWidth' | 'minimal'
  color?: string
}

export const Accordion: FC<Props> = ({
  children,
  id,
  text,
  icon,
  centered,
  marginBottom,
  childBlock,
  hiddenAt,
  showChevron = true,
  variant: variantProp = 'minimal',
  color,
}) => {
  const [open, setOpen] = useState<boolean>(false)

  const variant = sanitiseVariant(variantProp)
  const label = sanitiseLabel(text)

  const Block = (
    <Wrapper
      id={id}
      marginBottom={marginBottom}
      hiddenAt={hiddenAt}
      centered={centered}
      variant={variant}
    >
      <Opener
        href="#"
        onClick={(event) => {
          event.preventDefault()

          setOpen((currentState) => !currentState)
        }}
        variant={variant}
      >
        {icon && (
          <SupportingIcon
            icon={icon}
            marginBottom='{"xs":0}'
            // @ts-expect-error - This actually works.
            style={{ width: '10px', height: '10px' }}
          />
        )}
        {label}

        {showChevron &&
          (variant === 'fullWidth' ? (
            <FullWidthToggleIcon open={open} />
          ) : (
            <MinimalToggleIcon open={open} />
          ))}
      </Opener>
      <Content className={classnames({ open })}>
        <Row>
          <Col col={12} noGutter>
            {children}
          </Col>
        </Row>
      </Content>
    </Wrapper>
  )

  if (childBlock) return Block

  return (
    <Container>
      <Row>
        <Col col={12} lg={8} lgOffset={2} noGutter={false}>
          {Block}
        </Col>
      </Row>
    </Container>
  )
}
